import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../../assets/styles/pages/notFound.module.scss';

const NotFound = () => {
	return (
		<div className={styles.not_found}>
			<div className={styles.not_found_text}>You are on a non-existent web page.</div>
			<div className={styles.not_found_text}>We have no idea how you got here.</div>
			<Link to='/'>
				<div>Back to the Home page</div>
			</Link>
		</div>
	);
};

export default NotFound;
