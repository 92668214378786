import styles from '../../../assets/styles/services/webServices.module.scss';
import { ourServicesData } from '../../../data/ourServicesData.js';

const WebServices = () => {
	return (
		<div className={styles.webService}>
			<div className={styles.webService_wrapper}>
				{ourServicesData.map((service) => (
					<div className={styles.card} key={service.id}>
						<div className={styles.card_icon}>
							<img src={service.icon} alt={service.alt} />
						</div>
						<div className={styles.card_text}>
							<h3>{service.title}</h3>
							<p>{service.text}</p>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default WebServices;
