import React, { useState } from 'react';
import styles from '../../assets/styles/readMore.module.scss';

const ReadMore = (props) => {
	const [isReadMore, setIsReadMore] = useState(true);

	const toggleReadMore = () => {
		setIsReadMore(!isReadMore);
	};

	const multiParagraphs = (arr, maxLength) => {
		let long = 0;
		for (let i = 0; i < arr.length; i++) {
			if (long + arr[i].props.children.length > maxLength) {
				let res = arr.slice(0, i);
				let partial = arr[i].props.children.substring(0, maxLength - long);
				res.push(<p key={props.children}>{partial}</p>);
				return res;
			}
			long += arr[i].props.children.length;
		}
		return arr;
	};

	return (
		<div className={styles.text}>
			{isReadMore ? (
				<>{multiParagraphs(props.children, 150)}</>
			) : (
				props.children
			)}
			<span onClick={toggleReadMore} className={styles.text_toggle}>
				{isReadMore ? ' ...read more' : ' show less'}
			</span>
		</div>
	);
};

export default ReadMore;
