import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import styles from '../../assets/styles/title.module.scss';

const TitleAnimation = ({ title }) => {
	useEffect(() => {
		AOS.init();
	}, []);

	return (
		<h2 className={styles.title} data-aos='fade-up' data-aos-duration='2000'>
			{title}
		</h2>
	);
};

export default TitleAnimation;
