import { urlAddress } from '../components/helpers/urlAddress';

export const rolesData = [
	{
		id: 1,
		title: 'As a Patient',
		text: "More than 3.4 billion Africans live in rural areas, making it difficult for health equity high due to social, economic, and cultural diversity among us. Our vision is to drive toward digital health care inclusion from the bottom of the pyramid (BoP). And to help reduce the patient's out-of-pocket expenditures i.e. Physician consultation, medication, and lab test samples are currently offered at a very high price. It’s your responsibility as a patient to seek care early because you save life and others too. Together we can make a healthier society in Africa.",
		icon: require('../assets/images/patient.svg').default,
		alt: 'doctor illustration',
		link: 'JoinUs',
		href: `${urlAddress}/patient`,
	},
	{
		id: 2,
		title: 'As a Doctor',
		text: 'A simple solution where doctors can consult patients remotely from the comfort of their own home or clinic at any time. Doctors can provide their patients with more continuity and human care, which strengthens the doctor-patient relationship and experience. It will shorten the time it takes doctors to respond to patients in need of care and can refer patients to appropriate medical specialists for in-person visits based on their health care needs. We are amazed by the number of doctors who have already signed up, and you can do so as well.',
		icon: require('../assets/images/doctor.svg').default,
		alt: 'doctor illustration',
		link: 'JoinUs',
		href: `${urlAddress}`,
	},
	{
		id: 6,
		title: 'As a Nurse & Midwife',
		text: "Nurses and midwives are the engines that will energize Africa's success in providing quality health care services. As a result, the World Health Organization (WHO) declared 2020 to be the International Year of the Nurse and Midwife. Carelyo recognizes these values, which is why we are pioneering the mission and vision of creating new opportunities to bring care where it is most needed, through the most caring hands.",
		icon: require('../assets/images/nurse.svg').default,
		alt: 'Nurse / Midwives illustration',
		link: 'JoinUs',
		href: `${urlAddress}/Nurse&Midwives`,
	},	
	{
		id: 7,
		title: 'As a Community Health Practitioner',
		text: "Who will reach the unreached? Community Health Practitioners (CHPs) will do so. These are the people who are very familiar with their villages. We will train, compensate, and equip them with the necessary digital and technological tools to ensure that resources destined for the medically underserved, such as vaccines, bednets, prenatal care, and even people living with chronic diseases, are delivered on time. CARELYO believes that CHPs will not only help solve the last mile problem but will also serve as advocates for preventive care.",
		icon: require('../assets/images/communityhealthworker.svg').default,
		alt: 'Medical Lab Scientist illustration',
		link: 'JoinUs',
		href: `${urlAddress}/CommunityHealthPractinioner`,
	},
	{
		id: 7,
		title: 'As a Medical Lab Scientist',
		text: "Early detection and investigation of biological specimens are exceptionally crucial for our well-being. Laboratory scientists perform and process thousands of these test samples daily. It must be reported immediately so medical doctors can make an informed decision regarding their patient's treatment, diagnosis, hospital admission, and discharge. This takes time and is still very expensive today. Carelyo is leading this innovation and is bringing comfort including new job opportunities to unemployed laboratory scientists. Don't miss out.",
		icon: require('../assets/images/medicalLab.svg').default,
		alt: 'Medical Lab Scientist illustration',
		link: 'JoinUs',
		href: `${urlAddress}/medicalLab`,
	},
	{
		id: 3,
		title: 'Hospitals',
		text: "Public hospitals are faced with a high volume of unsolicited out-patient visitation and unnumbered in-patient hospitalizations. But our tertiary institutions still operate the old way, with manual book recording and hips of patient files that aren’t eco-friendly. The good news is CARELYO Hospital platform. This offers a patient-centric system that makes it easy to digitally confirm every hospital visit before the patient's visit. Public health workers now can know their patients before physical contact, to ensure the safety of workers safety isn’t compromised. Patients are handled with compassion so trust and confidence are restored in our healthcare. CARELYO is a 100% free digital healthcare system solution for public healthcare providers.",
		icon: require('../assets/images/hospital.svg').default,
		alt: 'hospital illustration',
		link: 'JoinUs',
		href: `${urlAddress}/hospital`,
	},
	{
		id: 4,
		title: 'Pharmacies',
		text: "It's hard to preach accessible healthcare when our society lacks genuine and affordable medication across all levels. CARELYO provides a fully digital pharmacy experience for patients and pharmaceutical stores. With more accredited pharmaceutical stores nationwide then we can eradicate sales of fake drugs. CARELYO's give you access to a free digital pharmacy solution with zero installation or cost. Patients can ease obtain their medication faster and safer. It is free forever.",
		icon: require('../assets/images/pharmacist.svg').default,
		alt: 'pharmacy illustration',
		link: 'JoinUs',
		href: `${urlAddress}/pharmacy`,
	},
	{
		id: 5,
		title: 'Laboratory',
		text: 'Test requests and results management for out-patient preventive care and in-patient caregiving facilities in our hospitals are lacking. CARELYO offers seamless and easy-to-use interfaces for commercial laboratories. With the click of a button, requested tests are immediately accessible, with no more papers moving around. Lab scientists can take the best safety measures while performing their tasks and uploading test results. Digital lab for retrieving and reporting is free forever for your laboratory. Start your new journey to become more patient-centric with CARELYO.',
		icon: require('../assets/images/laboratory.svg').default,
		alt: 'laboratory illustration',
		link: 'JoinUs',
		href: `${urlAddress}/laboratory`,
	},
];
