import styles from '../../assets/styles/quote.module.scss';
import quoteLeft from '../../assets/images/quoteLeft.svg';
import quoteRight from '../../assets/images/quoteRight.svg';

const Quote = () => {
	return (
		<div className={styles.quote}>
			<div className={styles.quote_text}>
				<img src={quoteLeft} alt='q' className={styles.quoteLeft} />
				We cannot sit and expect the problem to solve itself or wait to respond when the next{' '}
				<span>Ebola</span>, <span>HIV</span>, or <span>African-generated</span> form of{' '}
				<span>COVID 19</span> raises its ugly head.
				<img src={quoteRight} alt='q' className={styles.quoteRight} />
			</div>
		</div>
	);
};

export default Quote;
