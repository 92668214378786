import { useState, useEffect } from 'react';
import styles from '../../assets/styles/scrollToTop.module.scss';
import scrollTop from '../../assets/images/scrollToTop.svg';

const ScrollToTop = () => {
	const [isVisible, setIsVisible] = useState('');

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};

	useEffect(() => {
		const toggle = () => {
			window.scrollY > 400 ? setIsVisible(true) : setIsVisible(false);
		};

		window.addEventListener('scroll', toggle);

		return () => window.removeEventListener('scroll', toggle);
	}, []);

	return (
		<>
			{isVisible && (
				<img
					src={scrollTop}
					alt='arrow'
					className={`${styles.scroll} ${styles.visible}`}
					onClick={scrollToTop}
				/>
			)}
		</>
	);
};

export default ScrollToTop;
