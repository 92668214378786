import Partners from './components/sections/partners/Partners';
import Greeting from './components/sections/Greeting';
import Services from './components/sections/services/Services';
import Goals from './components/sections/Goals';

const Page = () => {
	return (
		<>
			<Greeting />
			<Partners />
			<Services />
			<Goals />
		</>
	);
};

export default Page;
