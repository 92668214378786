import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useState, useEffect, useRef, useCallback } from 'react';
import styles from '../../assets/styles/header/header.module.scss';
import { headerLinksData } from '../../data/headerLinksData.js';
import { FaBars, FaTimes } from 'react-icons/fa';
import logo from '../../assets/images/logo.svg';
import { Link } from 'react-scroll';

const Header = () => {
	const ref = useRef();
	const [toggleMenu, setToggleMenu] = useState(false);
	const location = useLocation();

	const handleClick = () => setToggleMenu(!toggleMenu);
	const closeMobileMenu = useCallback(() => {
		setToggleMenu(false);
	}, []);

	//close menu click outside
	useEffect(() => {
		const checkIfClickedOutside = (e) => {
			if (toggleMenu && ref.current && !ref.current.contains(e.target)) {
				setToggleMenu(false);
			}
		};
		document.addEventListener('mousedown', checkIfClickedOutside);
		return () => {
			document.removeEventListener('mousedown', checkIfClickedOutside);
		};
	}, [toggleMenu]);

	//close menu for press esc button
	useEffect(() => {
		const close = (e) => {
			if (e.keyCode === 27) {
				closeMobileMenu();
			}
		};
		window.addEventListener('keydown', close);
		return () => window.removeEventListener('keydown', close);
	}, [closeMobileMenu]);

	//prevent body scroll
	useEffect(() => {
		const body = document.querySelector('body');
		body.style.overflow = toggleMenu ? 'hidden' : 'auto';
	}, [toggleMenu]);

	return (
		<header className={styles.header}>
			<div className={styles.header_wrapper}>
				<NavLink className={styles.header_logo} to='/'>
					<img src={logo} alt='logotype' width={156} height={94} />
				</NavLink>
				<div className={styles.mobile_burger} onClick={handleClick}>
					{toggleMenu ? <FaTimes size={25} style={{cursor: 'pointer'}}/> : <FaBars size={25} style={{cursor: 'pointer'}}/>}
				</div>
				<div
					className={
						toggleMenu ? `${styles.header_links} ${styles.active}` : styles.header_links
					}>
					<span className={styles.mobile_title}>Menu</span>

					{
						location.pathname === '/news' ? (
						<ul ref={ref} className={styles.mobile_nav_wrapper}>
							<li className={styles.mobile_nav_item} onClick={closeMobileMenu}>
								<NavLink to='/' className={styles.nav_link}>
									Home
								</NavLink>
							</li>
							<li className={styles.mobile_nav_item} onClick={closeMobileMenu}>
								<NavLink to='/contactUs' className={styles.nav_link}>
									ContactUs
								</NavLink>
							</li>
							<li className={styles.mobile_nav_item} onClick={closeMobileMenu}>
								<NavLink to='/joinUs' className={styles.nav_link}>
									JoinUs
								</NavLink>
							</li>
						</ul>
					) : location.pathname.includes('/news/') ? (
						<ul ref={ref} className={styles.mobile_nav_wrapper}>
							<li className={styles.mobile_nav_item} onClick={closeMobileMenu}>
								<NavLink to='/' className={styles.nav_link}>
									Home
								</NavLink>
							</li>
							<li className={styles.mobile_nav_item} onClick={closeMobileMenu}>
								<NavLink to='/news' className={styles.nav_link}>
									News
								</NavLink>
							</li>
							<li className={styles.mobile_nav_item} onClick={closeMobileMenu}>
								<NavLink to='/contactUs' className={styles.nav_link}>
									ContactUs
								</NavLink>
							</li>
							<li className={styles.mobile_nav_item} onClick={closeMobileMenu}>
								<NavLink to='/joinUs' className={styles.nav_link}>
									JoinUs
								</NavLink>
							</li>
						</ul>
					) :
							location.pathname.includes('/contactUs') ? (
						<ul ref={ref} className={styles.mobile_nav_wrapper}>
							<li className={styles.mobile_nav_item} onClick={closeMobileMenu}>
								<NavLink to='/' className={styles.nav_link}>
									Home
								</NavLink>
							</li>
							<li className={styles.mobile_nav_item} onClick={closeMobileMenu}>
								<NavLink to='/news' className={styles.nav_link}>
									News
								</NavLink>
							</li>
							<li className={styles.mobile_nav_item} onClick={closeMobileMenu}>
								<NavLink to='/joinUs' className={styles.nav_link}>
									JoinUs
								</NavLink>
							</li>
						</ul>
					) : location.pathname.includes('/joinUs') ? (
						<ul ref={ref} className={styles.mobile_nav_wrapper}>
							<li className={styles.mobile_nav_item} onClick={closeMobileMenu}>
								<NavLink to='/' className={styles.nav_link}>
									Home
								</NavLink>
							</li>
							<li className={styles.mobile_nav_item} onClick={closeMobileMenu}>
								<NavLink to='/news' className={styles.nav_link}>
									News
								</NavLink>
							</li>
							<li className={styles.mobile_nav_item} onClick={closeMobileMenu}>
								<NavLink to='/contactUs' className={styles.nav_link}>
									ContactUs
								</NavLink>
							</li>
						</ul>
					) : location.pathname.includes('/policy') ? (
						<ul ref={ref} className={styles.mobile_nav_wrapper}>
							<li className={styles.mobile_nav_item} onClick={closeMobileMenu}>
								<NavLink to='/' className={styles.nav_link}>
									Home
								</NavLink>
							</li>
							<li className={styles.mobile_nav_item} onClick={closeMobileMenu}>
								<NavLink to='/news' className={styles.nav_link}>
									News
								</NavLink>
							</li>
							<li className={styles.mobile_nav_item} onClick={closeMobileMenu}>
								<NavLink to='/contactUs' className={styles.nav_link}>
									ContactUs
								</NavLink>
							</li>
						</ul>
					) : location.pathname.includes('/services') ? (
						<ul ref={ref} className={styles.mobile_nav_wrapper}>
							<li className={styles.mobile_nav_item} onClick={closeMobileMenu}>
								<NavLink to='/' className={styles.nav_link}>
									Home
								</NavLink>
							</li>
							<li className={styles.mobile_nav_item} onClick={closeMobileMenu}>
								<NavLink to='/news' className={styles.nav_link}>
									News
								</NavLink>
							</li>
							<li className={styles.mobile_nav_item} onClick={closeMobileMenu}>
								<NavLink to='/contactUs' className={styles.nav_link}>
									ContactUs
								</NavLink>
							</li>
						</ul>
					) : (
						<ul ref={ref} className={styles.mobile_nav_wrapper}>
							{headerLinksData.map((link) => {
								return (
									<li key={link.id} className={styles.mobile_nav_item}>
										<Link
											activeClass='active'
											className={styles.nav_link}
											to={link.href}
											onClick={closeMobileMenu}
											spy={true}
											smooth={true}
											offset={-150}
											duration={500}>
											{link.title}
										</Link>
									</li>
								);
							})}
							<li className={styles.mobile_nav_item} onClick={closeMobileMenu}>
								<NavLink to='/contactUs' className={styles.nav_link}>
									ContactUs
								</NavLink>
							</li>
							<li className={styles.mobile_nav_item} onClick={closeMobileMenu}>
								<NavLink to='/news' className={styles.nav_link}>
									News
								</NavLink>
							</li>
							<li className={styles.mobile_nav_item} onClick={closeMobileMenu}>
								<NavLink to='/joinUs' className={styles.nav_link}>
									JoinUs
								</NavLink>
							</li>
						</ul>
					)}
				</div>
			</div>
		</header>
	);
};

export default Header;
