import React, { useState, useEffect } from 'react';
import SwiperServices from './SwiperServices';
import styles from '../../../assets/styles/services/services.module.scss';
import WebServices from './WebServices';
import Quote from '../Quote';
import TitleAnimation from '../../helpers/TitleAnimation';

const Services = () => {
	const [width, setWidth] = useState(window.innerWidth);
	const breakpoint = 768;
	const handleWindowResize = () => {
		setWidth(window.innerWidth);
	};

	useEffect(() => {
		window.addEventListener('resize', handleWindowResize);
		return () => window.removeEventListener('resize', handleWindowResize);
	}, []);

	return (
		<div id='services' className={styles.services_section}>
			<TitleAnimation title='Our Services' />
			<div className={styles.swiper_container}>
				{width < breakpoint ? <SwiperServices /> : <WebServices />}
			</div>
			<Quote />
		</div>
	);
};

export default Services;
