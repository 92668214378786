import styles from '../../assets/styles/footer/footer.module.scss';
import instagram from '../../assets/images/socialIcons/instagram.svg';
import twitter from '../../assets/images/socialIcons/twitter.svg'
import linkedin from '../../assets/images/socialIcons/linkedin.svg';
import facebook from '../../assets/images/socialIcons/facebook.svg';
import footerLogo from '../../assets/images/logo.svg';
import nurseman from '../../assets/images/footer/usual/nursman.svg';
import board from '../../assets/images/footer/usual/board.svg';
import nurse from '../../assets/images/footer/usual/nurse.svg';

const Footer = () => {
	return (
		<footer className={styles.footer}>
			<div className={styles.footer_wrapper}>
				<div className={styles.logo}>
					<img src={footerLogo} alt='logo' width="156" height="94"/>
				</div>
				<div className={styles.wrapper_copyright}>
					<div className={styles.wrapper_copyright__social}>
						<ul>
							<li>
								<a
									href='https://www.facebook.com/carelyohealthcare'
									target='_blank'
									rel='noreferrer'
									aria-label="Read more about Carelyo's on Facebook">
									<img src={facebook} alt='facebook' width="30" height="30"/>
								</a>
							</li>
							<li>
								<a
									href='https://twitter.com/CarelyoHealth'
									target='_blank'
									rel='noreferrer'
									aria-label="Read more about Carelyo's on Twitter">
									<img src={twitter} alt='twitter' width="30" height="30"/>
								</a>
							</li>
							<li>
								<a
									href='https://www.linkedin.com/in/carelyo/'
									target='_blank'
									rel='noreferrer'
									aria-label="Read more about Carelyo's on Linkedin">
									<img src={linkedin} alt='linkedin' width="30" height="30"/>
								</a>
							</li>
							<li>
								<a
									href='https://www.instagram.com/carelyocarelyo/'
									target='_blank'
									rel='noreferrer'
									aria-label="Read more about Carelyo's on instagram">
									<img src={instagram} alt='instagram' width="30" height="30"/>
								</a>
							</li>
						</ul>
					</div>
					<div className={styles.wrapper_copyright__swedcon}>Powered by SWEDCON 18 AB</div>
					<div className={styles.wrapper_copyright__line} />
					<div className={styles.wrapper_copyright__copy}>
						&copy; {new Date().getFullYear()} Carelyo Nigeria. All right reserved.
					</div>
				</div>
			</div>
				<img src={nurse} alt='tree' className={styles.nurse} />
				<img src={nurseman} alt='snowman' className={styles.nurseman} />
				<img src={board} alt='santa' className={styles.board} />
		</footer>
	);
};

export default Footer;
