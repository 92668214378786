import React from 'react';
import styles from '../../assets/styles/goals.module.scss';
import { goalsData } from '../../data/goalsData';
import ReadMore from '../helpers/ReadMore';
import TitleAnimation from '../helpers/TitleAnimation';

const Goals = () => {
	return (
		<div id='goals' className={styles.goals}>
			<TitleAnimation title='Our Goals' />
			<div className={styles.goals_wrapper}>
				{goalsData.map((goal) => (
					<div className={styles.goal} key={goal.id}>
						<div className={styles.goal_image}>
							<img
								src={goal.icon}
								alt={goal.alt}
								className={styles.goal_image_icon}
								width={200}
								height={200}
							/>
						</div>
						<div className={styles.goal_description}>
							<ReadMore>
								<p>{goal.p1}</p>
								<p>{goal.p2}</p>
								<p>
									<a href={goal.linkTo} target='_blank' rel='noreferrer'>
										<img src={goal.linkToIcon} alt='link' className={styles.goals_link} />
									</a>
								</p>
							</ReadMore>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default Goals;
