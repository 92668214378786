export const ourServicesData = [
	{
		id: 1,
		title: 'Easy consultation booking',
		text: 'Book a consultation with a medical professional in just 5 seconds i.e., equivalent to five very simple steps from wherever you are. It’s cheaper than a loaf of bread. We are 100% patient-centric.',
		icon: require('../assets/images/consultation.svg').default,
		alt: 'consultation',
	},
	{
		id: 2,
		title: 'Drug prescription',
		text: "Your medication shows up instantly in your dashboard as your doctor prescribes. It's secure and accessible directly on your smartphone. Buy online with discounts, or visit accredited pharmacies close to you.",
		icon: require('../assets/images/prescription.svg').default,
		alt: 'prescription',
	},
	{
		id: 3,
		title: 'Hospital referencing',
		text: 'Healthcare is a priority, not a privilege. At no additional cost, our medical professionals will refer you to the right specialist at a clinic or hospital nearest to you. Saving you time and money. Trust us.',
		icon: require('../assets/images/referencing.svg').default,
		alt: 'referencing',
	},
	{
		id: 4,
		title: 'Laboratory integration',
		text: 'Identifying the health symptoms you are experiencing, early, is vital for preventing costly treatment of chronic illness, dependency on lifelong medication, or sudden death in the worst-case.',
		icon: require('../assets/images/laboratory.svg').default,
		alt: 'integration',
	},
];
