import React, { createContext, useEffect, useState } from 'react';
import axios from 'axios';
import { reactUrl, getNewsUrl } from '../helpers/apiURL';

export const NewsContext = createContext({});

export const NewsContextProvider = (props) => {
	const [newsBlog, setNewsBlog] = useState([]);

	let headers = {
		'Content-Type': 'application/json',
		'Access-Control-Allow-Origin': '*',
	};

	const getNews = async () => {
		const res = await axios.get('https://carelyo.ng/api/i/news/getAll', { headers });
		setNewsBlog(res.data);
	};

	console.log(newsBlog, 'SHOW ME STRUCTURE');

	useEffect(() => {
		getNews().then(r => r);
	}, []);

	return <NewsContext.Provider value={[newsBlog]}>{props.children}</NewsContext.Provider>;
};
