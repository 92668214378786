export const partnersData = [
	{
		id: 1,
		image: require('../assets/images/partnerSlides/OracleRed.svg').default,
		alt: 'partner',
	},
	{
		id: 2,
		image: require('../assets/images/partnerSlides/Företagsfabriken.svg').default,
		alt: 'partner',
	},
	{
		id: 3,
		image: require('../assets/images/partnerSlides/Högskolan.svg').default,
		alt: 'partner',
	},
	{
		id: 4,
		image: require('../assets/images/partnerSlides/nackademin.svg').default,
		alt: 'partner',
	},
	{
		id: 5,
		image: require('../assets/images/partnerSlides/EcutBilding.svg').default,
		alt: 'partner',
	},
];
