import styles from '../../assets/styles/greeting.module.scss';
import doctor1 from '../../assets/images/doctorResize.svg';
// import { urlAddress } from '../helpers/urlAddress';
import { joinus } from '../helpers/urlAddress';

const Greeting = () => {
	return (
		<div className={styles.greeting_section}>
			<div className={styles.greeting_section_wrapper}>
				<div className={styles.hero_section_box}>
					<h1 className={styles.hero_title}>
						Your health is important, 
						take care of it always.
					</h1>
					<div className={styles.text}>
						{/* <span className='hash'>#</span>
						<span className='tag'>HealthIsPriority</span> */}
						<p>
							<span style={{fontWeight: 'bold'}}>10x</span> more people will conveniently access care.
						</p>
					</div>
					<div className={styles.buttons}>						
						<a
							href={`/joinus`}
							className={`${styles.button} ${styles.buttons_white}`}>
							Join Us
						</a>
					</div>
				</div>
				<div className={styles.greeting_section_image}>
					<img src={doctor1} alt='doctor' className={styles.doctor} />
				</div>
			</div>
		</div>
	);
};

export default Greeting;
