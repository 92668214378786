import React from 'react';
import { useForm } from 'react-hook-form';
import '../../assets/styles/contactUs.scss';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import TitleAnimation from '../helpers/TitleAnimation';
import axios from 'axios';
import { reactUrl, contactFormUrl } from '../helpers/apiURL';

const ContactUs = (url, config) => {
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm();

	const toastifySuccess = () => {
		toast.success(
			`Your message has been sent.
			We will back to you as soon as possible`,
			{
				position: 'bottom-right',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
			},
		);
	};

	const toastifyAlert = () => {
		toast.error(
			`Sorry! Something went wrong.
			Try again later.`,
			{
				position: 'bottom-right',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
			},
		);
	};

	let handleOnSubmit = async (data) => {
		await axios
			.post('https://carelyo.ng/api/contact_form', data)
			.then(() => {
				toastifySuccess();
				reset();
			})
			.catch(() => {
				toastifyAlert();
			});
	};

	return (
		<>
			<div className='contact_form'>
				<div className='container'>
					<TitleAnimation title='Contact Us' />
					<div className='description'>
						Do you have any questions or suggestion? <br /> Feel free to ask and propose.
					</div>
					<form
						id='contact-form'
						onSubmit={handleSubmit(handleOnSubmit)}
						noValidate
						method='post'>
						<div className='formRow'>
							<label htmlFor='senderName'>
								Name<sup>&#42;</sup>
							</label>
							<input
								type='text'
								name='senderName'
								{...register('senderName', {
									required: { value: true, message: 'Please enter your name' },
									maxLength: {
										value: 30,
										message: 'Please use 30 characters or less',
									},
								})}
								className='form-control formInput'
								placeholder='John Doe'
							/>
							{errors.senderName && <span className='errorMessage'>{errors.senderName.message}</span>}
						</div>
						<div className='formRow'>
							<label htmlFor='senderEmail'>
								Email<sup>&#42;</sup>
							</label>
							<input
								type='email'
								name='senderEmail'
								{...register('senderEmail', {
									required: true,
									pattern:
										/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
								})}
								className='form-control formInput'
								placeholder='example@email.xx'
							/>
							{errors.senderEmail && (
								<span className='errorMessage'>Please enter a valid email address</span>
							)}
						</div>
						<div className='formRow'>
							<label htmlFor='senderMobile'>
								Mobile
							</label>
							<input
								type='tel'
								name='senderMobile'
								{...register('senderMobile')}
								className='form-control formInput'
								placeholder='+xxxxxxxxxxxxx'
							/>
						</div>
						<div className='formRow custom_select'>
							<label htmlFor='topic'>
								Topic<sup>&#42;</sup>
							</label>
							<select
								name='topic'
								{...register('topic', {
									required: { value: true },
								})}
								className='form-control formInput'
							>
								<option value='' disabled hidden>Select topic</option>
								<option value='Patient'>Patient</option>
								<option value='Doctor'>Doctor</option>
								<option value='Hospital'>Hospital</option>
								<option value='Pharmacy'>Pharmacy</option>
								<option value='Laboratory'>Laboratory</option>
								<option value='Partnership or Business'>Partnership or Business</option>
								<option value='I need help with'>I need help with...</option>
							</select>
							<span className='custom-arrow'> </span>
							{errors.topic && (
								<span className='errorMessage'>Please select a topic</span>
							)}
						</div>
						<div className='formRow'>
							<div className='col'>
								<label htmlFor='messageSubject'>
									Subject<sup>&#42;</sup>
								</label>
								<input
									type='text'
									name='messageSubject'
									{...register('messageSubject', {
										required: { value: true, message: 'Please enter a subject' },
									})}
									className='form-control formInput'
									placeholder='Subject'
								/>
								{errors.messageSubject && (
									<span className='errorMessage'>{errors.messageSubject.message}</span>
								)}
							</div>
						</div>
						<div className='formRow'>
							<div className='col'>
								<label htmlFor='messageBody'>
									Message<sup>&#42;</sup>
								</label>
								<textarea
									rows={3}
									name='messageBody'
									{...register('messageBody', {
										required: true,
									})}
									className='form-control formInput'
									placeholder='Your message here...'
								/>
								{errors.messageBody && (
									<span className='errorMessage'>Please enter a message</span>
								)}
							</div>
						</div>
						(<sub>&#42;</sub>) - field required
						<button className='submit-btn' type='submit'>
							Submit
						</button>
					</form>
				</div>
				<ToastContainer />
			</div>
		</>
	);
};

export default ContactUs;
