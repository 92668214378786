import React, { useContext, useState, useEffect, useMemo } from 'react';
import styles from '../../assets/styles/pages/news.module.scss';
import TitleAnimation from '../helpers/TitleAnimation';
import { Link } from 'react-router-dom';
import { NewsContext } from '../contexts/NewsContext';
import Pagination from '../helpers/pagination/Pagination';

let pageSize = 5;

const News = () => {
	const [newsBlog] = useContext(NewsContext);
	const [currentPage, setCurrentPage] = useState(1);

	const paginationData = useMemo(() => {
		const firstPageIndex = (currentPage - 1) * pageSize;
		const lastPageIndex = firstPageIndex + pageSize;
		return newsBlog.slice(firstPageIndex, lastPageIndex);
	}, [currentPage, newsBlog]);

	useEffect(() => {
		window.scrollTo({
			behavior: 'smooth',
			top: '0px',
		});
	}, [currentPage]);

	return (
		<>
			<div className={styles.news}>
				<TitleAnimation title='News' />
				<div className={styles.news_wrapper}>
					{paginationData.map((item) => {
						return (
							<Link
								key={item.id}
								to={`/news/${item.id}`}
								className={`${styles[`cards`]} ${styles.card}`}
								style={{
									backgroundImage: `url(data:image/png;base64,${item.image})`,
									backgroundPosition: 'center',
									backgroundSize: 'cover',
									backgroundRepeat: 'no-repeat',
								}}>
								<div className={styles.cards_wrapper}>
									<div className={styles.cards_content}>
										<h2>{item.title}</h2>
										<h3>{item.category}</h3>
									</div>
								</div>
							</Link>
						);
					})}
				</div>
				<Pagination
					className='pagination-bar'
					currentPage={currentPage}
					totalCount={newsBlog.length}
					pageSize={pageSize}
					onPageChange={(page) => setCurrentPage(page)}
				/>
			</div>
		</>
	);
};

export default News;
