import styles from '../../../assets/styles/partners/partners.module.scss';
import leftQuote from '../../../assets/images/leftquote.svg';
import rightQuote from '../../../assets/images/rightquotes.svg';
import TitleAnimation from '../../helpers/TitleAnimation';
import { partnersData } from '../../../data/partnersData';
import Marquee from 'react-fast-marquee';

const Partners = () => {
	return (
		<div id='partners' className={`${styles.partners_section}`}>
			<TitleAnimation title='Our Partners' />
			<div className={`${styles.partners_section_title}`}>
				<img src={leftQuote} className={styles.quote} alt='left quote' width='20' height='54' />
				<h2 className={styles.quote_text}>
					Who is <span>making this come true</span> with us.
				</h2>
				<img
					src={rightQuote}
					className={styles.quote}
					alt='right quote'
					width='20'
					height='54'
				/>
			</div>
			<Marquee duration={10000} speed={20} gradientWidth={100} pauseOnHover={true} style={{ gap: '0'}}>
				{partnersData.map((role) => {
					return (
						<img
							key={role.id}
							src={role.image}
							alt='icon'
							className={styles.marguee}
							width='160'
							height='50'
						/>
					);
				})}
			</Marquee>
		</div>
	);
};

export default Partners;
