import React from 'react';
import Roles from './Roles';
import styles from '../../assets/styles/pages/signUp.module.scss';

const JoinUs = () => {
	return (
		<div className={styles.signUp}>
			<div className={styles.signUp_wrapper}>
				<Roles />
			</div>
		</div>
	);
};

export default JoinUs;
