import { Route, Switch } from 'react-router-dom';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import Page from './Page';
import ContactUs from './components/pages/ContactUs';
import News from './components/pages/News';
import NewsDetails from './components/pages/NewsDetails';
import { NewsContextProvider } from './components/contexts/NewsContext';
import JoinUs from './components/pages/JoinUs';
// import PromotionLabel from './components/helpers/PromotionLabel';
import Policy from './components/pages/Policy';
import Services from './components/pages/Services';
import Cookie from './components/helpers/Cookie';
import ScrollToTop from './components/helpers/ScrollToTop';
import GoToTop from './components/helpers/GoToTop';
import NotFound from './components/pages/NotFound';
// import VideoButton from './components/helpers/VideoButton';

const App = () => {
	return (
		<>
			<Header />
			{/*<PromotionLabel />*/}
			{/*<VideoButton />*/}
			<NewsContextProvider>
				<Switch>
					<Route exact path='/' component={Page} />
					<Route path='/joinUs' component={JoinUs} />
					<Route path='/contactUs' component={ContactUs} />
					<Route exact path='/news' component={News} />
					<Route path='/news/:id' component={NewsDetails} />
					<Route path='/policy' component={Policy} />
					<Route path='/services' component={Services} />
					<Route component={NotFound} />
				</Switch>
			</NewsContextProvider>
			<Cookie />
			<ScrollToTop />
			<GoToTop />
			<Footer />
		</>
	);
};

export default App;
