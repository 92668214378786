import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Autoplay, Virtual } from 'swiper/core';
import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import { ourServicesData } from '../../../data/ourServicesData.js';
import '../../../assets/styles/services/swiperDots.css';
import styles from '../../../assets/styles/services/services.module.scss';

SwiperCore.use([Navigation, Pagination, Autoplay, Virtual]);

const SwiperServices = () => {
	return (
		<Swiper
			slidesPerView='auto'
			loop={false}
			grabCursor={true}
			centeredSlides={true}
			pagination={{
				clickable: true,
				dynamicBullets: true,
				dynamicMainBullets: 1,
				bulletClass: 'custom-bullet-black',
				modifierClass: 'swiper-pagination-custom',
			}}
			className='mySwiper'>
			{ourServicesData &&
				ourServicesData.map((service) => (
					<SwiperSlide key={service.id} className={styles['swiper-slide']}>
						<div className={styles.services_icon}>
							<img src={service.icon} alt={service.alt} />
						</div>
						<h3 className={styles.services_text_title}>{service.title}</h3>
						<p className={styles.services_text_content}>{service.text}</p>
					</SwiperSlide>
				))}
		</Swiper>
	);
};

export default SwiperServices;
