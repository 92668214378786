export const goalsData = [
	{
		id: 1,
		icon: require('../assets/images/goals/Goal1.svg').default,
		p1: "Eradicating poverty is not a task of charity, it's an act of justice and the key to unlocking an enormous human potential. Still, nearly half of the world’s population lives in poverty, and lack of food and clean water is killing thousands every single day of the year.",
		p2: 'Together, we can feed the hungry, wipe out disease and give everyone in the world a chance to prosper and live a productive and rich life.',
		linkTo: 'http://www.globalgoals.org/1-no-poverty',
		linkToIcon: require('../assets/images/goals/goalsLink.svg').default,
		alt: 'poverty',
		backColor: 'white',
	},
	{
		id: 2,
		icon: require('../assets/images/goals/Goal2.svg').default,
		p1: 'Hunger is the leading cause of death in the world. Our planet has provided us with tremendous resources, but unequal access and inefficient handling leaves millions of people malnourished.',
		p2: 'If we promote sustainable agriculture with modern technologies and fair distribution systems, we can sustain the whole world’s population and make sure that nobody will ever suffer from hunger again.',
		linkTo: 'http://www.globalgoals.org/2-zero-hunger',
		linkToIcon: require('../assets/images/goals/goalsLink.svg').default,
		alt: 'hunger',
		backColor: 'white',
	},
	{
		id: 3,
		icon: require('../assets/images/goals/Goal3.svg').default,
		p1: 'Over the last 15 years, the number of childhood deaths has been cut in half. This proves that it is possible to win the fight against almost every disease. Still, we are spending an astonishing amount of money and resources on treating illnesses that are surprisingly easy to prevent.',
		p2: 'The new goal for worldwide Good Health promotes healthy lifestyles, preventive measures and modern, efficient healthcare for everyone.',
		linkTo: 'http://www.globalgoals.org/3-good-health-and-well-being',
		linkToIcon: require('../assets/images/goals/goalsLink.svg').default,
		alt: 'health',
		backColor: 'white',
	},
	{
		id: 4,
		icon: require('../assets/images/goals/Goal4.svg').default,
		p1: 'Education liberates the intellect, unlocks the imagination and is fundamental for self-respect. It is the key to prosperity and opens a world of opportunities, making it possible for each of us to contribute to a progressive, healthy society.',
		p2: 'Learning benefits every human being and should be available to all.',
		linkTo: 'http://www.globalgoals.org/4-quality-education',
		linkToIcon: require('../assets/images/goals/goalsLink.svg').default,
		alt: 'quality',
		backColor: 'white',
	},
	{
		id: 5,
		icon: require('../assets/images/goals/Goal5.svg').default,
		p1: 'Gender bias is undermining our social fabric and devalues all of us. It is not just a human rights issue; it is a tremendous waste of the world’s human potential. By denying women equal rights, we deny half the population a chance to live life at its fullest.',
		p2: 'Political, economic and social equality for women will benefit all the world’s citizens. Together we can eradicate prejudice and work for equal rights and respect for all.',
		linkTo: 'http://www.globalgoals.org/5-gender-equality',
		linkToIcon: require('../assets/images/goals/goalsLink.svg').default,
		alt: 'gender',
		backColor: 'white',
	},
];
