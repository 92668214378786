import React from 'react';
import { Link } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';
import styles from '../../assets/styles/pages/policy.module.scss';

const Cookie = () => {
	return (
		<CookieConsent
			expires={365}
			enableDeclineButton
			buttonText='I accept'
			style={{
				background: '#444141',
				color: '#2feac1',
				display: 'flex',
				justifyContent: 'center',
				textAlign: 'center',
				fontWeight: 'bold',
			}}
			buttonStyle={{
				background: '#1a7564',
				color: '#ffffff',
			}}>
			This website uses cookies to enhance the user experience.{' '}
			<Link to='/policy'>
				<span className={styles.privacy}>Privacy Policy</span>
			</Link>
			,{' '}
			<Link to='/services'>
				<span className={styles.privacy}> Services Agreement Terms</span>
			</Link>
		</CookieConsent>
	);
};

export default Cookie;
