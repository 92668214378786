const monthNames = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
];

const d = new Date();
const getFullDate = `${d.getDate()} ${monthNames[d.getMonth()]} ${d.getFullYear()}`;

export default getFullDate;

