import React from 'react';
import styles from '../../assets/styles/roles/roles.module.scss';
import { rolesData } from '../../data/rolesData';
import TitleAnimation from '../helpers/TitleAnimation';

const Roles = () => {
	return (
		<div className={styles.roles} id='signup'>
			<TitleAnimation title='JoinUs' />
			<div className={styles.roles_wrapper}>
				{rolesData.map((role) => {
					return (
						<div
							className={styles.card}
							key={role.id}
							id={role.anchor}
							data-aos='zoom-in-down'
							data-aos-duration='700'
							data-aos-delay='200'>
							<div className={styles.card_image}>
								<img src={role.icon} alt={role.alt} />
							</div>
							<div className={styles.card_content}>
								<h3 className={styles.card_content_title}>{role.title}</h3>
								<p className={styles.text}>{role.text}</p>
								<a href={role.href} className={styles.link}>
									{role.link}{' '}
								</a>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default Roles;
