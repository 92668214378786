export const headerLinksData = [
	{
		id: 1,
		href: 'partners',
		title: 'Partners',
	},
	{
		id: 2,
		href: 'services',
		title: 'Services',
	},
	{
		id: 3,
		href: 'goals',
		title: 'Goals',
	},
];
