export default function svDev() {
	let swUrl = `${process.env.PUBLIC_URL}/sw.js`;
	if ('serviceWorker' in navigator) {
		navigator.serviceWorker.register(swUrl)
			.then((reg) => {
				reg.update();
				// console.log('SW registered!');
			})
			.catch((error) => error)
	}
}

