import React, { useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import styles from '../../assets/styles/pages/newsDetails.module.scss';
import { NewsContext } from '../contexts/NewsContext';

const NewsDetails = () => {
	const [newsBlog] = useContext(NewsContext);
	let params = useParams();
	console.log(newsBlog);
	const details = useMemo(() => {
		if (!newsBlog) {
			return {};
		} else {
			return newsBlog.find((element) => element.id == params.id);
		}
	}, [params.id, newsBlog]);

	return (
		<div className={styles.news_details}>
			{details && (
				<div className={styles.news_details_wrapper}>
					<h2>{details.title}</h2>
					<img src={`data:image/png;base64,${details.image}`} alt='i' style={{ width: '500px', height: '500px' }}/>
					<p>{details.content}</p>
					<div>Author: {details.writtenBy}</div>
					<div>{details.timeStamp}</div>
				</div>
			)}
		</div>
	);
};

export default NewsDetails;
